const charityColors = {
  "penny-appeal-ca": "#ee8226",
  "penny-appeal-us": "#ee8226",
  "islamic-relief-uk": "#009fe3",
  "national-zakat-foundation-uk": "#c9333a",
  "read-foundation-uk": "#51b848",
};

export default {
  charityColors,
};
