<template>
  <div class="zch-tooltip">
    <img src="../../assets/question.svg" :alt="hint" />
    <span class="zch-tooltip-bubble">{{ hint }}</span>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    hint: String,
  },
};
</script>

<style lang="scss" scoped>
.zch-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    min-height: 20px;
    min-width: 20px;
  }
}

.zch-tooltip-bubble {
  visibility: hidden;
  width: 220px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #303030;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -112px;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  @media screen and (max-width: 992px) {
    left: -80px;
  }
}

.zch-tooltip .zch-tooltip-bubble::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #303030 transparent transparent transparent;
  @media screen and (max-width: 992px) {
    left: 200px;
  }
}

.zch-tooltip:hover .zch-tooltip-bubble {
  visibility: visible;
}
</style>
