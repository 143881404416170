<template>
  <div class="charities">
    <div
      v-for="(charity, key) in charities"
      v-bind:key="key"
      class="charity-card"
    >
      <div
        class="cover"
        :style="charityColor(charity.charity)"
        :class="charity.charity"
      >
        <img :src="charityLogo(charity.charity)" alt="" />
        <button @click="proceed(charity)">
          {{ cardButtonString }}
        </button>
      </div>
      <div class="content">
        {{ charity.about }}
      </div>
    </div>
  </div>
</template>

<script>
import CharitiesSvc from "@/service/charities";
export default {
  name: "Charities",
  props: {
    charities: Array,
    isWidgetOrigin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    charityLogo(charityId) {
      return require(`@/assets/charities/${charityId}/${charityId}_logo.svg`);
    },

    charityColor(charityId) {
      return `background-color: ${CharitiesSvc.charityColors[charityId]}`;
    },
    proceed(charityId) {
      this.$emit("proceed", charityId);
    },
  },
  computed: {
    cardButtonString() {
      return this.isWidgetOrigin ? "Continue" : "Select";
    },
  },
};
</script>

<style lang="scss" scoped>
.charities {
  display: flex;
  flex-wrap: wrap;
  width: 65%;

  @media screen and (max-width: 992px) {
    margin: auto;
    width: 90%;
  }

  @media screen and (max-width: 752px) {
    width: 100%;
  }

  @media screen and (max-width: 540px) {
    display: block;
  }

  .charity-card {
    border-radius: 12px;
    box-shadow: 0 0 14.5px 0 rgba(0, 0, 0, 0.23);
    background-color: #ffffff;
    max-width: 360px;
    max-height: 500px;

    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin-bottom: 20px;
    transition: 500ms;

    &:nth-child(even) {
      margin-left: 20px;
      @media screen and (max-width: 992px) {
        margin-left: auto;
      }
    }

    @media screen and (min-width: 2400px) {
      &:nth-child(3) {
        margin-left: 20px;
      }
    }

    @media screen and (max-width: 992px) {
      margin: 20px auto 20px auto;
      width: unset;
    }

    .cover {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      background-color: #51b848;
      min-height: 240px;

      img {
        max-width: 80%;
        margin: 40px 0;
      }

      &.islamic-relief-uk {
        img {
          margin: 10px;
        }
      }

      button {
        width: 85%;
        padding: 15px 0;
        margin: 0 0 20px 0;
        border-radius: 50px;
        background-color: #ffffff;
        border: none;
        font-family: Muli, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.13px;
        color: $color-cod-gray;
        cursor: pointer;
        transition: opacity 500ms;
        text-transform: uppercase;
        &:hover {
          opacity: 0.6;
        }
      }
    }

    .content {
      padding: 20px;
      font-family: Muli, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: $color-cod-gray;
    }
  }
}
</style>
