<template>
  <div class="zch-zakat" v-if="!selectedCharity">
    <div v-if="!charities" class="loading">
      <p>Calculating your Zakat...</p>
      <LoadingAnimation />
    </div>
    <div v-else class="container">
      <h1>Select Charity &amp; Pay</h1>
      <div class="row">
        <Charities
          v-if="charities"
          :charities="charities"
          :isWidgetOrigin="isWidgetOrigin"
          @proceed="proceed"
        />
        <Details
          :valueOwed="valueOwed"
          :valueOwned="valueOwned"
          :nisab="nisab"
          :assetsDisplay="assetsDisplayed"
          :assets="assets"
          :zakatable="zakatable"
          :currency="currency"
        />
      </div>
    </div>
  </div>

  <Payment
    v-else
    :charityId="selectedCharity.charity"
    :conversationId="conversationId"
    :assets="assets"
    :total="zakatable"
    :currency="currency"
    :charity="selectedCharity"
  />
</template>

<script>
import CharitiesAPI from "@/api/charities";
import ConversationAPI from "@/api/conversation";
import ZakatSummarySvc from "@/service/zakat-summary";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";
import ConversationSvc from "@/service/conversation";
import Charities from "../components/Charities/Charities";
import Details from "../components/Details/Details";
import Payment from "../components/Payment/Payment";
import { stateHandler } from "../main";

export default {
  name: "Zakat",
  components: {
    LoadingAnimation,
    Charities,
    Details,
    Payment,
  },
  data() {
    return {
      charities: null,
      assetsDisplayed: false,
      assets: [],
      currency: null,
      charity: null,
      conversationId: ConversationSvc.getConversationID(),
      zakatable: 0,
      valueOwed: 0,
      valueOwned: 0,
      nisab: 0,
      country: "gb",
      selectedCharity: null,
      isWidgetOrigin: false,
    };
  },
  methods: {
    async loadCharities() {
      if (typeof this.charity === "string" && this.charity.length > 0) {
        try {
          const charity = await CharitiesAPI.fetchCharity(this.charity);
          this.charities = [charity];
          return;
        } catch (err) {
          console.log(err);
          return;
        }
      }

      try {
        const charities = await CharitiesAPI.fetchCharities(
          ZakatSummarySvc.getCountryByCurrency(this.currency)
        );
        this.charities = charities;
      } catch (err) {
        console.log(err);
      }
    },
    loadZakatDetails() {
      ConversationAPI.getZakatDetails(this.conversationId).then((resp) => {
        this.assets = resp.data["asset_groups"];
        this.currency = resp.data["currency"];
        this.zakatable = resp.data["zakatable"];
        this.valueOwed = resp.data["value_owed"];
        this.valueOwned = resp.data["value_owned"];
        this.nisab = resp.data["nisab"];
        this.charity = resp.data["charity"];

        this.loadCharities();
      });
    },

    formatFunds(funds, isInPennies = false) {
      if (!this.currency) {
        return "";
      }
      return new Intl.NumberFormat("en-EN", {
        style: "currency",
        currency: this.currency,
      }).format(isInPennies ? funds / 100 : funds);
    },

    proceed(charity) {
      if (charity.redirect_url) {
        // This is only used for national-zakat-foundation-uk.
        // For other charities to use it we will need a more robust solution
        const zakatAmount = (this.zakatable / 100).toFixed(2);
        window.top.location.href = `${charity.redirect_url}?give&zakat=${zakatAmount}&utm_campaign=calculate&utm_medium=app&utm_source=zakchat`;
      } else {
        this.selectedCharity = charity;
      }
    },
  },
  mounted() {
    this.loadZakatDetails();

    stateHandler.$on("back", () => {
      this.selectedCharity = null;
    });
  },
};
</script>

<style lang="scss" scoped>
.zch-zakat {
  width: 100%;
  padding-bottom: 100px;

  h1 {
    font-family: Muli, sans-serif;
    font-size: 37px;
    font-weight: 800;
    line-height: 0.71;
    color: $color-cod-gray;
    width: 100%;
    margin: 40px 0;

    @media screen and (max-width: 992px) {
      line-height: 1;
    }
  }

  .container {
    width: 78%;
    margin: 0 11%;

    @media screen and (max-width: 992px) {
      margin: 0 30px;
      width: calc(100% - 60px);
    }

    .row {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 992px) {
        flex-direction: column-reverse;
      }
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-family: Muli, sans-serif;
    font-size: 24px;
    color: $color-cod-gray;
  }
}
</style>
