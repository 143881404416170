<template>
  <div class="zch-payment">
    <div class="container">
      <div class="fields">
        <div class="zakatBox">
          <h1>Pay</h1>
          <div class="row spaced max-width mobile-centered">
            <div class="subheading thisguy">Your donation amount:</div>
            <div class="editableZakat">
              <div class="paymentAmount">{{ formatFunds(this.total) }}</div>
              <div class="strikearound">or</div>
              <div class="custom">
                <input
                  type="text"
                  v-model="donationAmountInput"
                  :placeholder="`Custom amount`"
                  @input="handleDonationInput"
                  maxlength="8"
                />
              </div>
            </div>
          </div>
          <div class="tip">
            <div class="notice">
              ZAKchat doesn't take anything from your donation. Help us keep
              running ZAKchat by donating an optional tip.
            </div>
            <div class="selection">
              <span> Thank you for including a tip of: </span>
              <TipDropdown
                :passedList="tips"
                :currency="currency"
                @update="updateTip"
              />
            </div>
            <div class="custom">
              <input
                type="text"
                v-model="tipInput"
                :placeholder="`Custom input`"
                @input="handleTipInput"
                maxlength="8"
              />
            </div>
          </div>
        </div>
        <h2>Personal info</h2>
        <div class="input-item">
          <label> First/Last Name </label>
          <div class="fields">
            <input type="text" v-model="userInfo.name" />
            <input type="text" v-model="userInfo.surname" />
          </div>
        </div>
        <div class="input-item">
          <label> Mobile Number </label>
          <input type="text" v-model="userInfo.phoneNumber" />
        </div>
        <div class="input-info">We'll remind you to pay Zakat annually</div>
        <h2>Payment details</h2>
        <div class="input-item">
          <label> Card Number </label>
          <div id="card-number"></div>
        </div>
        <div class="input-item">
          <label> Expiry/CVC </label>
          <div class="fields">
            <div id="card-expiry"></div>
            <div id="card-cvc"></div>
          </div>
        </div>
        <div class="input-item">
          <label> Address </label>
          <input type="text" v-model="userInfo.address" />
        </div>
        <div class="input-item">
          <label> Postcode </label>
          <input type="text" v-model="userInfo.postcode" />
        </div>
        <div class="checkboxes">
          <div class="item">
            <Checkbox
              id="marketingEmails"
              :defaultValue="false"
              :callback="'onMarketingEmailsUpdate'"
            />
            <label for="marketingEmails">
              I am happy to be contacted by {{ charityName }} with information
              about their projects, fundraising activities and appeals via
              email. We will pass your details to {{ charityName }}.
            </label>
          </div>
          <div class="item">
            <Checkbox
              id="reminderEmail"
              :defaultValue="false"
              :callback="'onZakatReminderUpdate'"
            />
            <label for="reminderEmail"
              >Remind me again via email to pay my Zakat next year.</label
            >
          </div>
        </div>
        <div class="zch-gift-aid" v-if="charity.gift_aid">
          <div class="row">
            <div class="notice">
              Your donation of {{ formatFunds(donation) }} can become
              {{ formatFunds(donation * 1.25) }}
            </div>
            <img src="../../assets/giftaid.svg" alt="" />
          </div>
          <p>
            If you are a UK taxpayer the value of your gift can increase by 25%
            at no extra cost to you through Gift Aid.
            <a href="https://www.gov.uk/donating-to-charity/gift-aid"
              >Read more about Gift Aid.</a
            >
          </p>
          <div class="checkbox-item">
            <Checkbox
              id="giftaid"
              :defaultValue="false"
              :callback="`onGiftAidUpdated`"
            />
            <label for="giftaid">
              GiftAid it! I confirm I am a UK taxpayer and I would like
              {{ charityName }} to treat this donation as a Gift Aid donation. I
              understand that if I pay less income tax and/or Capital Gains tax
              than the amount of Gift Aid claimed on all my donations in that
              year, it is my responsibility to pay any difference.
            </label>
          </div>
          <div class="doubled-info">
            <img src="../../assets/info-sm.svg" alt="Info" />
            <span> Only your original donation can be increased </span>
          </div>
        </div>
      </div>
      <div class="receipt">
        <div class="cover" :style="charityColor(charityId)">
          <img :src="charityLogo(charityId)" alt="" />
        </div>
        <div class="content">
          <div class="subheading">SUBTOTAL:</div>
          <div class="item">
            <div class="item-name">Donation Amount</div>
            <div class="item-value">
              {{ formatFunds(donation) }}
            </div>
          </div>
          <div class="item">
            <div class="item-name">ZAKchat Tip</div>
            <div class="item-value">
              {{ formatFunds(tip) }}
            </div>
          </div>
          <div class="item">
            <div class="item-name">
              <span> Transaction Fee </span>
              <Tooltip
                hint="This ensures that the charity gets 100% of your Zakat donation."
              />
            </div>
            <div class="item-value">
              {{ formatFunds(transactionFee, true) }}
            </div>
          </div>
          <div class="total">
            <div class="name">TOTAL:</div>
            <div class="value">
              {{ getTotal }}
            </div>
          </div>
          <div v-if="this.errors.global" class="error">
            {{ this.errors.global }}
          </div>
          <div class="buttons">
            <div
              class="pay"
              v-bind:class="payButtonClass"
              @click="instantiatePaymentMethod"
            >
              <LoadingAnimation
                v-if="awaitingPayment"
                :isTiny="true"
                color="#fff"
              />
              <span v-else>Pay</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethodAPI from "@/api/payment_method";
import PaymentAPI from "@/api/payment";
import Checkbox from "@/components/Checkbox/Checkbox";
import UserSvc from "@/service/user";
import widget from "../../service/widget";
import CharitiesSvc from "@/service/charities";
import Tooltip from "../Tooltip/Tooltip";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { stateHandler } from "../../main";
import TipDropdown from "../TipDropdown/TipDropdown";
import { format } from "@/service/currencies";
import { calculateFee } from "@/service/payment";

export default {
  name: "Payment",
  components: {
    Checkbox,
    TipDropdown,
    LoadingAnimation,
    Tooltip,
  },
  props: {
    charityId: String,
    conversationId: String,
    assets: Array,
    total: Number,
    currency: String,
    charity: Object,
  },
  data() {
    return {
      stripe: null,
      elements: null,
      pubKey: null,
      awaitingPayment: false,
      card: {
        cvc: null,
        expiry: null,
        number: null,
      },
      userInfo: {
        name: "",
        surname: "",
        phoneNumber: "",
        address: "",
        postcode: "",
      },
      errors: {
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        global: null,
      },
      marketingEmails: false,
      giftAid: false,
      zakatReminder: true,
      tips: [0, 1000, 2500, 5000],
      tip: 0,
      tipInput: "",
      charityName: this.charity.name,
      donationAmountInput: "",
      donationAmount: this.total,
    };
  },
  methods: {
    async fetchPublishableKey() {
      this.pubKey = await PaymentMethodAPI.getStripePublishableKey();
      this.loadStripeInstance();
    },
    loadStripeInstance() {
      /* eslint-disable-next-line no-undef */
      this.stripe = Stripe(this.pubKey);
      this.initializeStripeElements(this.stripe);
    },
    initializeStripeElements(instance) {
      this.elements = instance.elements({
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Muli:400,500",
          },
        ],
      });

      const style = {
        base: {
          width: 300,
          color: "black",
          fontFamily: "Muli, sans-serif",
          fontSize: "16px",
          "::placeholder": {
            color: "#adadad",
          },
        },
      };

      this.card.cvc = this.elements.create("cardCvc", { style });
      this.card.expiry = this.elements.create("cardExpiry", { style });
      this.card.number = this.elements.create("cardNumber", { style });

      this.card.cvc.mount("#card-cvc");
      this.card.expiry.mount("#card-expiry");
      this.card.number.mount("#card-number");

      this.card.cvc.addEventListener("change", (event) =>
        this.handleEvent(event, "cardCvc")
      );
      this.card.number.addEventListener("change", (event) =>
        this.handleEvent(event, "cardNumber")
      );
      this.card.expiry.addEventListener("change", (event) =>
        this.handleEvent(event, "cardExpiry")
      );
    },
    handleEvent(event, element) {
      this.errors[element] = (event.error && event.error.message) || "";
    },
    handleStateMutations() {
      stateHandler.$on("onGiftAidUpdated", (val) => {
        this.giftAid = val;
      });
      stateHandler.$on("onZakatReminderUpdate", (val) => {
        this.zakatReminder = val;
      });
      stateHandler.$on("onMarketingEmailsUpdate", (val) => {
        this.marketingEmails = val;
      });
    },
    async instantiatePaymentMethod() {
      this.errors.global = null;
      if (this.awaitingPayment || this.validateInput()) {
        return;
      }

      if (this.errors.global) {
        return;
      }

      this.awaitingPayment = true;

      const idToken = UserSvc.getIDToken();
      const clientSecret = await PaymentMethodAPI.setupPaymentMethods();

      const data = {
        payment_method: {
          card: this.card.number,
          billing_details: {
            name: `${this.userInfo.name} ${this.userInfo.surname}`,
            address: {},
          },
        },
      };

      if (this.userInfo.phoneNumber) {
        data.payment_method.billing_details.phone = this.userInfo.phoneNumber;
      }

      if (this.userInfo.address) {
        data.payment_method.billing_details.address.line1 =
          this.userInfo.address;
      }

      if (this.userInfo.postcode) {
        data.payment_method.billing_details.address.postal_code =
          this.userInfo.postcode;
      }

      try {
        const result = await this.stripe.confirmCardSetup(clientSecret, data);
        const paymentResponse = await PaymentMethodAPI.submitPaymentMethod(
          idToken,
          result.setupIntent.payment_method
        );

        await PaymentAPI.submitPaymentInfo(idToken, {
          charity: this.charityId,
          campaign: "zch2020",
          conversation_id: this.conversationId,
          donation_amount: this.donationAmount,
          zakchat: this.tip,
          payment_method_id: paymentResponse.payment_method_id,
          gift_aid: this.giftAid,
          charity_marketing: this.marketingEmails,
          zakat_reminder: this.zakatReminder,
          phone: this.userInfo.phoneNumber,
        });
      } catch (e) {
        console.error(e);
        this.validateInput();
        this.awaitingPayment = false;
        return;
      }

      try {
        dataLayer.push({
          event: "payment submitted",
          id: this.conversationId,
          charity: this.charityID,
          currency: this.currency,
          amount: this.donation,
        });

        widget.emitDonation(this.donation, this.currency);
      } catch (e) {
        console.error(e);
      } finally {
        window.location = "/share";
      }
    },

    handleTipInput() {
      const tip = parseFloat(this.tipInput) || 0;
      this.tipInput = this.tipInput
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");

      if (tip <= 0) {
        stateHandler.$emit("onUserResetTipRadioMenu");
        this.tip = 0;
        this.tipInput = "";
        return;
      }

      const split = this.tipInput.split(".");
      if (split.length > 1) {
        this.tipInput = split[0] + "." + split[1].substring(0, 2);
      }

      this.tip = Math.floor(tip * 100);
      stateHandler.$emit("onTipInputUpdate");
    },

    handleDonationInput() {
      const donation = parseFloat(this.donationAmountInput) || 0;
      if (donation <= 0) {
        this.donationAmountInput = "";
        this.donationAmount = this.total;
        return;
      }

      this.donationAmountInput = this.donationAmountInput
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      const split = this.donationAmountInput.split(".");
      if (split.length > 1) {
        this.donationAmountInput = split[0] + "." + split[1].substring(0, 2);
      }

      this.donationAmount = Math.floor(donation * 100);
    },

    updateTip(tip) {
      this.tip = tip;
      this.tipInput = "";
    },

    formatFunds(funds) {
      funds = funds ? funds : 0;
      return format(funds / 100, this.currency);
    },

    validateInput() {
      if (this.donationAmount >= 9999999) {
        this.errors.global = `The donation must be less than ${this.formatFunds(
          99999999
        )}.`;
        return;
      }

      if (this.donationAmount <= 0) {
        this.errors.global = "Please choose a donation amount.";
        return;
      }

      if (this.tip >= 9999999) {
        this.errors.global = `The tip must be less than ${this.formatFunds(
          99999999
        )}.`;
        return;
      }

      if (this.tip < 0) {
        this.errors.global = "The tip cannot be less than zero";
        return;
      }

      if (!this.userInfo.name.toString().length) {
        this.errors.global = "Please enter your first name.";
        return;
      }

      if (!this.userInfo.surname.toString().length) {
        this.errors.global = "Please enter your surname.";
        return;
      }

      if (this.giftAid && !this.userInfo.address.toString().length) {
        this.errors.global = "Please enter your address.";
        return;
      }

      if (this.giftAid && !this.userInfo.postcode.toString().length) {
        this.errors.global = "Please enter your postcode.";
        return;
      }

      for (let error in this.errors) {
        if (this.errors[error]) {
          this.errors.global = this.errors[error];
        }
      }

      if (this.awaitingPayment) {
        this.awaitingPayment = false;
        return true;
      }

      return false;
    },
    charityLogo(charityId) {
      return require(`@/assets/charities/${charityId}/${charityId}_logo.svg`);
    },
    charityColor(charityId) {
      return `background-color: ${CharitiesSvc.charityColors[charityId]}`;
    },
    back() {
      stateHandler.$emit("back");
      return false;
    },
    calculateTransactionFee() {
      const total = this.donationAmount ? this.donationAmount : this.total;
      return calculateFee(total + this.tip, this.currency);
    },
  },
  computed: {
    getTotal() {
      if (!this.currency) {
        return "";
      }

      let total = this.donationAmount ? this.donationAmount : this.total;
      total += this.tip;

      total += this.calculateTransactionFee();

      return format(total / 100, this.currency);
    },
    payButtonClass() {
      return this.awaitingPayment ? "loading" : "";
    },
    transactionFee() {
      return this.calculateTransactionFee();
    },
    donation() {
      return this.donationAmount ? this.donationAmount : this.total;
    },
  },
  mounted() {
    this.handleStateMutations();
    this.fetchPublishableKey();
  },
};
</script>

<style lang="scss" scoped>
.zch-payment {
  width: 100%;
  padding-bottom: 100px;

  .editableZakat {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 900px) {
      margin-right: 17px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .spaced {
    justify-content: space-between;
  }

  .mobile-centered {
    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }

  .max-width {
    width: 100%;
  }

  .paymentAmount {
    font-family: Muli, sans-serif;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.52;
    text-align: center;
    color: #540039;
  }

  .strikearound {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #222222;

    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 2px solid #dcdcdc;
      margin: auto;
      width: 100%;
      margin: auto 2px;
    }
  }

  .subheading {
    font-family: Muli, sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.21;
    color: $color-cod-gray;

    &.thisguy {
      margin-top: 15px;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .custom {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    input {
      padding: 6px 15px;
      border: solid 0.7px rgba(84, 0, 57, 0.4);
      background-color: #fffafd;
      border-radius: 50px;
      width: 190px;
      font-family: Muli, sans-serif;
      font-size: 19.2px;
      font-weight: 500;
      line-height: 1.87;
      color: $color-blackberry;

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  h1 {
    font-family: Muli, sans-serif;
    font-size: 34px;
    font-weight: 800;
    line-height: 0.71;
    color: $color-cod-gray;
    width: 100%;

    @media screen and (max-width: 992px) {
      line-height: 1;
      text-align: center;
    }
  }

  .zakatBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 16px;

    & > * {
      margin-top: 20px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 78%;
    margin: 40px 11%;

    @media screen and (max-width: 992px) {
      margin: 30px 30px;
      width: calc(100% - 60px);
    }

    .fields {
      width: calc(60% - 34px);

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      .tip {
        border-radius: 14px;
        border: solid 0.7px rgba(84, 0, 57, 0.4);
        background-color: #f1e8ee;
        padding: 17px;

        .notice {
          font-family: Muli, sans-serif;
          font-size: 17px;
          line-height: 1.88;
          color: $color-cod-gray;
          width: 90%;
        }

        .selection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0 0 0;
          font-family: Muli, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 2;
          color: #667893;

          @media screen and (max-width: 992px) {
            display: block;
          }
        }
      }
    }

    h2 {
      width: 100%;
      font-family: Muli, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.58;
      color: $color-blackberry;
      border-bottom: solid 1px #dcdcdc;
    }

    .input-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        margin-top: 30px;
      }

      label {
        font-family: Muli, sans-serif;
        font-size: 16px;
        color: $color-cod-gray;
      }

      .fields,
      input {
        width: 70%;
      }

      input {
        border-radius: 30px;
        border: solid 0.7px #d1d1d1;
        background-color: #ffffff;
        padding: 16px 10px;
        font-family: Muli, sans-serif;
        font-size: 16px;
      }

      .fields {
        display: flex;
        justify-content: space-between;

        input {
          width: 48%;
        }
      }
    }

    .input-info {
      font-family: Muli, sans-serif;
      font-size: 16px;
      line-height: 1.25;
      color: #4f6079;
      margin-left: 30%;
      padding-top: 8px;
    }

    .checkboxes {
      margin-top: 43px;

      .item {
        display: flex;

        &:not(:first-child) {
          margin-top: 32px;
        }

        label {
          margin: 0 0 0 10px;
          cursor: pointer;
          font-family: Muli, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.38;
          color: #667893;
        }
      }
    }
  }

  .receipt {
    width: 40%;
    max-width: 410px;
    box-shadow: 0 19px 42px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    height: max-content;
    border-radius: 16px;

    @media screen and (max-width: 992px) {
      position: fixed;
      width: 100%;
      margin: 0;
      max-width: unset;
      left: 0;
      box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.44);
      border-radius: 0;
      bottom: 0;
    }

    .error {
      font-family: Muli, sans-serif;
      font-size: 16px;
      color: $color-tomato;
    }

    .cover {
      width: 100%;
      min-height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px 16px 0 0;
      background-color: #ee8226;

      @media screen and (max-width: 992px) {
        display: none;
      }

      img {
        max-width: 80%;
      }
    }

    .content {
      padding: 22px 25px 40px 22px;

      @media screen and (max-width: 992px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
      }

      .item {
        display: flex;
        justify-content: space-between;
        font-family: Muli, sans-serif;
        line-height: 1.45;
        font-size: 20px;
        margin-top: 12px;

        @media screen and (max-width: 992px) {
          display: none;
        }

        &-name {
          font-weight: 500;
          color: #6a6a6a;
          display: flex;

          span {
            margin-right: 5px;
          }
        }

        &-value {
          font-weight: bold;
          color: $color-cod-gray;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        margin-top: 20px;
        border-bottom: solid 1px #dcdcdc;
        border-top: solid 1px #dcdcdc;
        font-family: Muli, sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 1.21;
        color: $color-cod-gray;

        @media screen and (max-width: 992px) {
          display: none;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 35px;
        text-transform: uppercase;

        @media screen and (max-width: 992px) {
          margin: 0;
          width: 100%;
        }

        .back {
          font-family: Muli, sans-serif;
          font-size: 19px;
          font-weight: 400;
          letter-spacing: 0.81px;
          color: $color-cod-gray;
          text-decoration: underline;
          cursor: pointer;
          transition: opacity 500ms;

          &:hover {
            opacity: 0.7;
          }
        }

        .pay {
          width: 100%;
          padding: 11px 46px;
          border: 2px solid $color-blackberry;
          text-align: center;
          border-radius: 31.7px;
          background-color: $color-blackberry;
          font-family: Muli, sans-serif;
          font-size: 19px;
          font-weight: 800;
          letter-spacing: 0.81px;
          color: #ffffff;
          cursor: pointer;
          transition: 500ms;

          &:hover {
            background-color: #{$color-blackberry}cc;
          }
        }
      }
    }
  }
}

#card-expiry,
#card-cvc {
  width: 48%;
}

#card-number {
  width: 70%;
}

#card-expiry,
#card-cvc,
#card-number {
  border-radius: 30px;
  border: solid 0.7px #d1d1d1;
  background-color: #ffffff;
  padding: 16px 10px;
}

.zch-gift-aid {
  width: 100%;
  border-radius: 14px;
  border: solid 2px #e5eefb;
  padding: 20px;
  margin-top: 40px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .notice {
      width: 70%;
      font-family: Muli, sans-serif;
      font-size: 24px;
      line-height: 1.35;
      color: $color-cod-gray;
    }

    img {
      height: 100%;
    }
  }

  p {
    font-family: Muli, sans-serif;
    font-size: 16px;
    line-height: 1.88;
    color: #667893;

    a {
      color: #667893;
    }
  }

  .checkbox-item {
    display: flex;

    label {
      margin: -3px 0 0 10px;
      cursor: pointer;
      font-family: Muli, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      color: #667893;
    }
  }

  .doubled-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    font-family: Muli, sans-serif;
    font-size: 12px;
    line-height: 2.5;
    color: #667893;

    span {
      margin-left: 5px;
    }
  }
}
</style>
